import React from "react";
import "./updates.css";

const colors = {
  maroon: "#701a27", // accent
  blue: "#068D9D", // accent
  green: "#B5B967", // secondary
  platinum: "#E7E3E0", // prim
  white: "#FFFFFF", // prim
};

const Updates = () => {
  return (
    <div className="updates">
      <h2> Updates </h2>
      <hr className="headerLine" />
      <div>
        <b> Jul 2021 </b> Revisited React, created this portfolio, and deployed
        to Azure 🚀!
      </div>
      <hr className="divLine" />
      <div>
        <b> Jun 2021 </b> Delivered a full stack ML app (image recognition) @
        Chevron!
        <div>
          <em>
            gathering data => labeling images => evaluating a couple of existing
            internal models => setting up resources for ML (Databricks), backend
            (.Net), and frontend (Angular) => retraining with new data (transfer
            learning) => setting up data pipelines and inference endpoints =>
            going live 🥳!
          </em>
        </div>
      </div>
      <hr className="divLine" />
      <div>
        <b> Jun 2021 </b> Took a deep dive into learning PyTorch - my favorite
        deep learning framework now 🤖!
      </div>
      <hr className="divLine" />
      <div>
        <b> Jun 2021 </b> Second summer being a buddy for an MLE intern 😎!
      </div>
      <hr className="divLine" />
      <div>
        <b> May 2021 </b> Learned a lot more about Azure Pipelines. Built some
        build, unit, and integration testing pipelines for a python package 🐍!
      </div>
      <hr className="divLine" />
      <div>
        <b> Apr 2021 </b> Second year mentoring @ Chevron’s Data Science and
        Development Program (DSDP) ❤️!
        <div>
          <em>
            DSDP is a 6-month internal program to teach Chevron’s employees data
            science and machine learning skills.
          </em>
        </div>
      </div>
      <hr className="divLine" />
      <div>
        <b> Oct 2020 </b> Built data pipelines for ETL to/from data lake using
        Azure Databricks and Azure Data Factory 🏭.
      </div>
      <hr className="divLine" />
      <div>
        <b> Sep 2020 </b> Deployed the first version of aimeetstocks.com 💸!
      </div>
      <hr className="divLine" />
      <div>
        <b> Jul 2020 </b> Delivered my first project @ Chevron (built production
        regression models and data pipelines | deployed to Azure) 😊!
      </div>
      <hr className="divLine" />
      <div>
        <b> Jun 2020 </b> Started learning Django and React 🌐!
      </div>
      <hr className="divLine" />
      <div>
        <b> May 2020 </b> Wrote some scripts to get user posts from
        r/wallstreetbets, extract named entities and sentiments 😤.
      </div>
      <hr className="divLine" />
      <div>
        <b> Apr 2020 </b> Started mentoring 6 Chevron engineers in DSDP! Had a
        ton of fun teaching ML concepts 🤗!
      </div>
      <hr className="divLine" />
      <div>
        <b> Feb 2020 </b> Started as fulltime MLE @ Chevron 😆!
      </div>
      <hr className="divLine" />
      <div>
        <b> Dec 2019 </b> Graduated from Texas A&M University 🎓!
      </div>
    </div>
  );
};

export default Updates;
